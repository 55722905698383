import React from 'react';
import '../App.css';
import logo from './../assets/logo.png';
import fbLogo from './../assets/facebook.png';

export default function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <img src={logo} alt="Logo" className="App-logo"></img>
          <p>
            <a href="https://www.facebook.com/groups/968897077323643" target="_blank">
              <img src={fbLogo} alt="facebook logo" className="external-logo-link"></img>
              Come join us on Facebook!
            </a>
          </p>
          <p>
            The south Baltimore (SoBo) cleanup crew has been cleaning up the neighborhoods of
            Federal Hill, Federal Hill South, Riverside, and South Baltimore since 2019.
            <br/><br/>
            Originally founded by neighbor Susan Sperry in 2019, our group picks a few SoBo blocks
            every couple weeks, and meet up with our friends and neighboors to clean it. The blocks
            we clean are suggested by members or requested by the community.
            <br/><br/>
            Please consider joining our Facebook group and coming out to one of our cleanups!
          </p>
        </p>
        <br/>
      </header>
    </div>
  );
}
